var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"sign-up-header",staticStyle:{"background-image":"url('images/bg-signup.jpg')"}},[_c('div',{staticClass:"content"},[_c('h1',{staticClass:"mb-5"},[_vm._v(_vm._s(_vm.$t('auth.register.title')))]),_c('p',{staticClass:"text-lg"},[_vm._v(_vm._s(_vm.$t('auth.register.description')))])])]),_c('a-card',{staticClass:"card-signup header-solid h-full",attrs:{"bordered":false,"bodyStyle":{paddingTop: 0}}},[_c('p',{staticClass:"text-center my-25 font-semibold text-muted"}),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"mb-10"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'name',
						{ rules: [{ required: true, message: ("" + (_vm.$t('auth.register.fullName.required'))) }] } ]),expression:"[\n\t\t\t\t\t\t'name',\n\t\t\t\t\t\t{ rules: [{ required: true, message: `${$t('auth.register.fullName.required')}` }] },\n\t\t\t\t\t\t]"}],attrs:{"placeholder":_vm.$t('auth.register.fullName.placeholder')}})],1),_c('a-form-item',{staticClass:"mb-10"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'email',
						{ rules: [{ required: true, message: ("" + (_vm.$t('auth.register.email.required'))) }] } ]),expression:"[\n\t\t\t\t\t\t'email',\n\t\t\t\t\t\t{ rules: [{ required: true, message: `${$t('auth.register.email.required')}` }] },\n\t\t\t\t\t\t]"}],attrs:{"placeholder":_vm.$t('auth.register.email.placeholder')}})],1),_c('a-form-item',{staticClass:"mb-5"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'password',
						{ rules: [{ required: true, message: ("" + (_vm.$t('auth.register.password.required'))) }] } ]),expression:"[\n\t\t\t\t\t\t'password',\n\t\t\t\t\t\t{ rules: [{ required: true, message: `${$t('auth.register.password.required')}` }] },\n\t\t\t\t\t\t]"}],attrs:{"type":"password","placeholder":_vm.$t('auth.register.password.placeholder')}})],1),_c('a-form-item',{staticClass:"mb-10"},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'remember',
						{
							valuePropName: 'checked',
							initialValue: true,
							rules: [{
						        message: ("" + (_vm.$t('auth.register.term.required'))),
						        validator: function (_, value, callback) {
						            if(value != true){
						            	callback(value)
						            }else{
						            	callback()
						            }
						        }
					       	}]
						} ]),expression:"[\n\t\t\t\t\t\t'remember',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\tvaluePropName: 'checked',\n\t\t\t\t\t\t\tinitialValue: true,\n\t\t\t\t\t\t\trules: [{\n\t\t\t\t\t\t        message: `${$t('auth.register.term.required')}`,\n\t\t\t\t\t\t        validator: (_, value, callback) => {\n\t\t\t\t\t\t            if(value != true){\n\t\t\t\t\t\t            \tcallback(value)\n\t\t\t\t\t\t            }else{\n\t\t\t\t\t\t            \tcallback()\n\t\t\t\t\t\t            }\n\t\t\t\t\t\t        }\n\t\t\t\t\t       \t}]\n\t\t\t\t\t\t},\n\t\t\t\t\t\t]"}]},[_vm._v(" "+_vm._s(_vm.$t('auth.register.agree'))+" "),_c('a',{staticClass:"font-bold text-dark",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.$t('auth.register.term.title'))+" ")])])],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"loading":_vm.loading,"type":"primary","block":"","html-type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('auth.register.btn'))+" ")])],1)],1),_c('p',{staticClass:"font-semibold text-muted text-center"},[_vm._v(_vm._s(_vm.$t('auth.register.login'))+" "),_c('router-link',{staticClass:"font-bold text-dark",attrs:{"to":"/sign-in"}},[_vm._v(_vm._s(_vm.$t('auth.login.btn')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }