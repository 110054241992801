<!-- 
	This is the sign up page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div>

		<!-- Sign Up Image And Headings -->
		<div class="sign-up-header" style="background-image: url('images/bg-signup.jpg')">
			<div class="content">
				<h1 class="mb-5">{{$t('auth.register.title')}}</h1>
				<p class="text-lg">{{$t('auth.register.description')}}</p>
			</div>
		</div>
		<!-- / Sign Up Image And Headings -->
		
		<!-- Sign Up Form -->
		<a-card :bordered="false" class="card-signup header-solid h-full" :bodyStyle="{paddingTop: 0}">
			<!-- <template #title>
				<h5 class="font-semibold text-center">Register With</h5>
			</template>
			<div class="sign-up-gateways">
    			<a-button>
					<img src="images/logos/logos-facebook.svg" alt="">
				</a-button>
    			<a-button>
					<img src="images/logos/logo-apple.svg" alt="">
				</a-button>
    			<a-button>
					<img src="images/logos/Google__G__Logo.svg.png" alt="">
				</a-button>
			</div> -->
			<p class="text-center my-25 font-semibold text-muted"></p>
			<a-form
				id="components-form-demo-normal-login"
				:form="form"
				class="login-form"
				@submit="handleSubmit"
			>
				<a-form-item class="mb-10">
					<a-input
						v-decorator="[
						'name',
						{ rules: [{ required: true, message: `${$t('auth.register.fullName.required')}` }] },
						]"
						:placeholder="$t('auth.register.fullName.placeholder')"
					>
					</a-input>
				</a-form-item>
				<a-form-item class="mb-10">
					<a-input
						v-decorator="[
						'email',
						{ rules: [{ required: true, message: `${$t('auth.register.email.required')}` }] },
						]"
						:placeholder="$t('auth.register.email.placeholder')"
					>
					</a-input>
				</a-form-item>
				<a-form-item class="mb-5">
					<a-input
						v-decorator="[
						'password',
						{ rules: [{ required: true, message: `${$t('auth.register.password.required')}` }] },
						]"
						type="password"
						:placeholder="$t('auth.register.password.placeholder')"
					>
					</a-input>
				</a-form-item>
				<a-form-item class="mb-10">
					<a-checkbox
						v-decorator="[
						'remember',
						{
							valuePropName: 'checked',
							initialValue: true,
							rules: [{
						        message: `${$t('auth.register.term.required')}`,
						        validator: (_, value, callback) => {
						            if(value != true){
						            	callback(value)
						            }else{
						            	callback()
						            }
						        }
					       	}]
						},
						]"
					>
						{{$t('auth.register.agree')}}  <a href="#" class="font-bold text-dark">{{$t('auth.register.term.title')}} </a>
					</a-checkbox>
				</a-form-item>
				<a-form-item>
					<a-button :loading="loading" type="primary" block html-type="submit" class="login-form-button">
						{{$t('auth.register.btn')}}
					</a-button>
				</a-form-item>
			</a-form>
			<p class="font-semibold text-muted text-center">{{$t('auth.register.login')}} <router-link to="/sign-in" class="font-bold text-dark">{{$t('auth.login.btn')}}</router-link></p>
		</a-card>
		<!-- / Sign Up Form -->

	</div>
</template>

<script>
	import signup from '@/services/auth/signup'
	import { notification } from 'ant-design-vue';
	import { STATUS_INACTIVE, STATUS_ACTIVE } from '@/constant';

	export default ({
		data() {
			return {
				loading: false
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		methods: {
			// Handles input validation after submission.
			handleSubmit(e) {
				this.loading = true
				e.preventDefault();
				let thiss = this
				this.form.validateFields(async (err, values) => {
					if ( !err ) {
						let formData = new FormData();
						formData.append('name', values.name);
						formData.append('email', values.email);
						formData.append('password', values.password);
						const res = await signup(formData)
						if (res.status == STATUS_ACTIVE) {
							notification.success({
								message: this.$t('notify.success.registerAccount')
							});
							thiss.$router.push({ name: 'signIn' })
						} else if (res.status == STATUS_INACTIVE) {
							notification.error({
								message: this._.get(res, 'message')
							});
						}
					}
					this.loading = false
				});
			},
		},
	})

</script>

<style lang="scss">
</style>